@media (max-width: 600px) {
	.header-dummy div {
		padding-bottom: 60%;
	}
	
	.tight {
		margin-bottom: 50px;
	}
}

@media (max-width: 600px) {
	body {
		font-weight: 300;
	}
	
	h1, h2, h3, h4, h5, h6 {
		font-weight: 100;
	}
	
	.menu {
		display: none;
	}
	
	section {
		padding: 0 20px;
	}
}