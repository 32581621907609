@import "../../../source/scss/include.scss";

section.screenshots {
	.gallery a {
		display: inline-block;
		margin: 9px;
		line-height: 0;
	}
	
	@media (max-width: 1100px) {
		.gallery img {
			width: 100%;
			height: auto;
		}
		.gallery a {
			max-width: 45%;
		}
	}
	
	@media (max-width: 600px) {
		.gallery a {
			max-width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
}