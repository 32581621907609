@import "include.scss";

* {
	box-sizing: border-box;
}

body {
	background-color: black;
}

body, h1, h2, h3, h4, h5, h6 {
	font-family: 'Roboto', sans-serif;
	font-weight: 100;
	font-size: 15px;
	letter-spacing: 0.8px;
	text-rendering: optimizeLegibility;
	color: $text-color;
}

p, footer {
	line-height: 1.5em;
	letter-spacing: 0.8px;
}

h1, h2, h3, h4, h5, h6 {
	@include hidpi(1.5) {
		color: #fff;
	}
}

a:link {
	position: relative;
	text-decoration: none;
	color: inherit;
	@include transition(all $transition-out ease-in);
}

a:visited {
	color: inherit;
}

a:hover {
	color: $link-hover-color;
	@include transition(all $transition-in ease-in);
}

input {
	color: black;
	border-radius: 3px;
	padding: 11px;
	text-align: center;
	font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 25px;
}

.button, input[type='submit'] {
	display: inline-block;
	background-color: #000;
	min-width: 140px;
	margin: 5px;
	padding: 11px 20px;
	border-radius: 3px;
	border: 1px solid #747474;
	font-weight: 300;
	letter-spacing: 0.8px;
	color: $text-color;
	@include transition(all $transition-out ease-in);
	
	@include hidpi(1.5) {
		font-weight: 300;
	}
}

.button.wide {
	min-width: 252px;
}

.button:hover, input[type='submit']:hover {
	background-color: white;
	color: #000;
	@include transition(all $transition-in ease-in);
}

.button:hover:after {
	content: none;
}

.button.disabled {
	opacity: 0.5;
}

.button.disabled:hover {
	color: $text-color;
	background-color: #000;
}

.lightbutton {
	width: 95px;
	display: inline-block;
	background-color: black;
	@include transition(all $transition-out ease-in);
	
	.fa {
		display: block;
		margin: 12px;
	}
}

.lightbutton:hover {
	color: #fff;
	
	.no-background-blend-mode & {
		color: $link-hover-color;
	}
}

.lightbutton:hover:after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	@include linear-gradient(#e7feb1, #5f5225);
	mix-blend-mode: multiply;
	
	.no-background-blend-mode & {
		background: none;
	}
}

.social .lightbutton {
	width: auto;
	.fa {
		margin: 0;
	}
}

.menu {
	width: 100%;
	padding: 8px 25px;
	font-size: 12px;
	background-color: #111;
	
	@include display(flex);
	@include flex-direction(row);
	@include justify-content(space-between);
	@include align-items(center);
	
	nav {
		font-weight: 300;
		
		ul {
			list-style-type: none;
			margin: 0; padding: 0;
			margin-right: 1em;
		}
		li {
			display: inline;
			margin-right: 1em;
		}
	}
	
	.social a {
		font-size: 17px;
		margin-left: 0.7em;
	}
}

header {
	position: relative;
	left: 50%;
	z-index: -100;
	padding: 0;
	width: 50%;
	height: 0;
	
	div {
		width: 200%;
		margin-left: -100%;
		text-align: center;
		overflow: hidden;
	}
	
	img {
		position: relative;
		left: 50%;
		margin-left: -100%;
		width: 130%;
		max-width: 1860px;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
	}
}

.container {
	position: relative;
	top: 0;
}

.header-dummy {
	width: 100%;
	max-width: 1480px;
	
	div {
		padding-bottom: 46%;
	}
	
	div.trailer-button {
		padding-bottom: 15%;
	}
}

section {
	max-width: 1100px;
	margin: auto;
	padding: 0 50px;
	
	text-align: center;
	@include display(flex);
	@include flex-direction(row);
	@include justify-content(center);
	@include flex-wrap(wrap);
	margin-bottom: 100px;
	
	> div {
		padding: 0 10px;
	}
	
	> div:first-child {
		padding-left: 0;
	}
	
	> div:last-child {
		padding-right: 0;
	}
}

.tight {
	margin-bottom: 110px;
	margin-top: -30px;
}

footer {
	text-align: center;
	font-size: 10px;
	margin-bottom: 75px;
	margin-top: 125px;
	
	h3 {
		margin-bottom: 60px;
	}
}

@import "blog.scss";
@import "mobile.scss";