@import "../../../source/scss/include.scss";

section.contact {
	form {
		margin: 12px;
	}
	input {
		height: 41px;
	}
	input.button {
		position: relative;
		top: 1px;
	}
	
	@include justify-content(space-around);
	
	div {
		margin: 25px 0;
	}
	
	.social {
		p {
			margin-top: 0;
			margin-bottom: 20px;
		}
		a {
			font-size: 32px;
			padding: 15px;
		}
	}
	
	.ratings {
		margin-top: 125px;
		width: 100%;
	}
}