section.header {
	margin-top: 100px;
}

section.blog {
	max-width: 700px;
	text-align: left;
	
	.post {
		margin-bottom: 200px;
	}
	
	h3 {
		font-size: 17px;
		font-weight: 400;
		margin: 17px 0;
	}
	
	p a:link {
		border-bottom: 1px solid #646464;
	}
	
	p a:hover {
		border-bottom: 1px solid $link-hover-color;
	}
	
	.section {
		margin-bottom: 100px;
	}
	
	input {
		margin: 0;
	}
	
	.commentlist {
		list-style-type: none;
		padding-left: 0;
	}
	
	.comment {
		margin-bottom: 50px;
	}
	
	.comment-body {
		div {
			display: inline;
		}
		p {
			margin-top: 5px;
		}
		p:before {
			content: "« ";
		}
		p:after {
			content: " »";
		}
		.comment-meta {
			float: left;
		}
		.comment-meta:after {
			content: "–";
			padding: 5px;
		}
		.comment-author .avatar {
			display: none;
		}
	}
}

@media (max-width: 600px) {
	.entry .img.size-full {
		max-width: 400px;
		height: auto;
	}
}