@import "../../../source/scss/include.scss";

section.trailer {
	display: block;
	$video-width: 100%;
	
	.video {
		position: relative;
		width: $video-width;
		padding-bottom: 9% / 16% * $video-width;
		
		.wrapper {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
		}
		
		iframe {
			width: 100%;
			height: 100%;
		}
	}
	
	.buy {
		width: 100% - $video-width;
	}
}