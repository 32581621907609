section.buy {
	.buy {
		font-size: 25px;
		font-weight: 100;
		margin-bottom: 1em;
	}
	.stores {
		margin: 15px 0;
	}
	.stores figure {
		margin: 12px;
	}
	.stores img {
		width: 35px;
		height: 35px;
	}
}