section.laurels {
	figure {
		min-width: 175px;
		margin: 1em 27px;
	}
	
	@media (max-width: 400px) {
		figure {
			width: 40%;
			min-width: unset;
			margin: 1em 12px;
			
			img {
				width: 100%;
			}
		}
	}
}