section.quotes {
	max-width: 860px;
}

section.quotes blockquote {
	p {
		font-size: 25px;
		margin-bottom: 0;
	}
	footer {
		font-size: 25px;
		margin-top: 0;
		padding-right: 25px;
		font-style: normal;
	}
	margin: 4em 0;
}